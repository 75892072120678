import { graphql } from 'gatsby';
import React from 'react';
import { ContactInfo } from '../components/ContactInfo';
import { Hero } from '../components/Hero';
import { Layout } from '../components/Layout';
import { ContentfulRichText } from '../components/RichText';
import { Section } from '../components/Section';
import { styled } from '../styles/styled';

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${(props) => props.theme.bp.md}) {
    grid-gap: ${(props) => props.theme.spacing(2)};
    grid-template-columns: 1fr 1fr;
  }
`;

const DetailSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${(props) => props.theme.bp.lg}) {
    grid-gap: ${(props) => props.theme.spacing(2)};
    grid-template-columns: 1fr 1fr;
  }
`;

const Details = styled.div`
  @media (min-width: ${(props) => props.theme.bp.lg}) {
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 350px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Caption = styled.figcaption`
  text-align: center;
  font-size: 0.85rem;
  & > :first-child {
    font-weight: 700;
  }
`;

const GroupList = styled.ul`
  font-size: 0.9rem;
  margin-bottom: ${(props) => props.theme.spacing(4)};
  list-style: none;
  position: relative;
  & > li {
    position: relative;
  }

  & ul {
    list-style-type: disc;
  }

  & & > li:before {
    content: none;
  }

  & > li:before {
    content: '';
    position: absolute;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    width: 8px;
    height: 8px;
    top: 12px;
    left: -23px;
    transform: translateY(-50%) rotate(-45deg);
  }
`;

const CredentialList = styled.ul`
  font-size: 0.9rem;
  list-style-type: circle;
`;

const ContactContainer = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${(props) => props.theme.spacing(4)};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(2)};
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`;

const ContactHeading = styled.h4`
  text-align: center;
  margin: 0 0 1rem;
`;

interface Props {
  data: {
    info: {
      name: string;
      role: string;
      titles: string[];
      picture: {
        file: {
          url: string;
        };
      };
      bio: {
        bio: string;
      };
      credentials: {
        credentials: string;
      };
      groups: {
        groups: string;
      };
    };
  };
}

export default ({ data: { info } }: Props) => {
  const fullName = `${info.name}, ${info.titles.join(', ')}`;
  const parsedBio = JSON.parse(info.bio.bio);
  const parsedCredentials = JSON.parse(info.credentials.credentials);
  const parsedGroups = JSON.parse(info.groups.groups);

  return (
    <Layout title="About Us">
      <Hero title="What makes us different" />
      <Section>
        <Main>
          <div>
            <figure>
              <Image src={info.picture.file.url} alt={fullName} />
              <Caption>
                <div>{fullName}</div>
                <div>{info.role}</div>
              </Caption>
            </figure>
          </div>
          <ContentfulRichText node={parsedBio} />
        </Main>
      </Section>
      <Section alt>
        <DetailSection>
          <Details>
            <ContentfulRichText
              components={{ 'unordered-list': GroupList }}
              node={parsedGroups}
            />
            <ContentfulRichText
              components={{ 'unordered-list': CredentialList }}
              node={parsedCredentials}
            />
          </Details>
          <ContactContainer>
            <ContactHeading>Want more information?</ContactHeading>
            <ContactInfo />
          </ContactContainer>
        </DetailSection>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query AbouPageQuery {
    info: contentfulTeamMember {
      name
      role
      titles
      picture {
        file {
          url
        }
      }
      credentials {
        credentials
      }
      bio {
        bio
      }
      groups {
        groups
      }
      # fields {
      #   bioJSON
      #   credentialsJSON
      #   groupsJSON
      # }
    }
  }
`;
